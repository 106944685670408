//
// footables.scss
//

.footable-odd {
    background-color: $white;
}

.footable-detail-show {
    background-color: $gray-100;
}

.footable-row-detail {
    background-color: #F0F4F7;
}

.footable-pagination {
    li {
        margin-left: 5px;
        float: left;
        a {
            position: relative;
            display: block;
            padding: .5rem .75rem;
            margin-left: -1px;
            line-height: 1.25;
            color: $dark;
            background-color: $white;
            border: 1px solid $gray-100;
            border-radius: 2px;
        }
    }
    li.active {
        a {
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }
    }
}

.footable>thead>tr>th>span.footable-sort-indicator {
    float: right;
}