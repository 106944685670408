.systemcard-c-lightindic {
    display:inline-block;
    width: 16px;
    height: 12px;
    background-color: #000;
    border-radius: 10px;
    vertical-align: middle;
}

.systemcard-c-lightindic.light-red {background-color: #EB412D;}
.systemcard-c-lightindic.light-orange {background-color: #FFBE1E;}
.systemcard-c-lightindic.light-green {background-color: #00CC8A;}
.systemcard-c-lightindic.light-off {background-color: #3a3a3a;}

.details-sysname {
    margin-top: 0;
    margin-bottom: 0;
}
.details-sysprops {
    font-size: 75%;
}

.col-sysprops p {
    margin-bottom: 0
}

.form-options-block {
    padding-top: calc(0.45rem + 1px);
    padding-bottom: calc(0.45rem + 1px);
}
