//
// mapeal-maps.scss
//

.mapael {
    .map {
        position: relative;

        .zoomIn {
            top: 25px;
        }
        
        .zoomOut {
            top: 50px;
        }
    }
    .zoomIn,
    .zoomOut,
    .zoomReset {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        border-radius: 2px;
        font-weight: 500;
        cursor: pointer;
        background-color: $primary;
        text-decoration: none;
        color: $white;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 10px;
        width: 24px;
        height: 24px;
        line-height: 24px;
    }

    .plotLegend {
        text {
            font-family: $font-family-base !important;
        }
    }
}