// 
// tables.scss
//

.table {
    th {
        font-weight: $font-weight-medium;
    }
}
//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

.small-action-btn {
    margin-top: -4px;
    margin-bottom: -4px;
    font-size: 20px;
    line-height: 20px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;

    i {
        vertical-align: middle;
    }
}

.shrink-col {
    width: 0.1%;
    white-space: nowrap;
}

// Partial header in a table
.table-subheader {
    background-color: #e4e9f3;

    td {

        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
    }
}
