.statuschip-lightindic {
    display:inline-block;
    width: 16px;
    height: 12px;
    background-color: #000;
    border-radius: 10px;
    vertical-align: middle;
}

.statuschip-iconindic {
    display:inline-block;
    width: 16px;
    vertical-align: middle;
}

.statuschip-lightindic.light-red {background-color: #EB412D;}
.statuschip-lightindic.light-orange {background-color: #FFBE1E;}
.statuschip-lightindic.light-green {background-color: #00CC8A;}
.statuschip-lightindic.light-off {background-color: #3a3a3a;}
.statuschip-lightindic.light-unk {background-color: #999999;}
.statuschip-lightindic.light-tbd {background-color: transparent; border: 1px solid #3a3a3a}
.statuschip-lightindic.light-lck {background-color: transparent; border: 1px solid #EB412D}
