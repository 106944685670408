.map-pin {
    color: #ffffff;
    background: #00CC8A;
    height: 32px;
    width: 32px;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    transition: 0.1s;
    
    &.light-red {background-color: #EB412D;}
    &.light-orange {background-color: #FFBE1E;}
    &.light-green {background-color: #00CC8A;}
    &.light-off {background-color: #3a3a3a;}
}

.map-pin i{
    font-size: 24px;
}

.map-pin.hovered {
    z-index: 1000;
    transform: translate(-50%, -50%) scale(1.2);

    cursor: pointer;

    &.light-red {background-color: lighten(#EB412D, 15%);}
    &.light-orange {background-color: lighten(#FFBE1E, 15%);}
    &.light-green {background-color: lighten(#00CC8A, 15%);}
    &.light-off {background-color: lighten(#3a3a3a, 15%);}
}


.map-pin-tooltip {
    color: #000;
    padding: 6px;
    border-radius: 6px;
    font-size: 14px;
    background-color: #fff;
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-width : 100px;
    position: absolute;
    top: -40px;
    left: 0;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.map-infobox {
    color: #000;
    border: 1px solid rgb(0, 0, 0);
    background-color: #fff;
    width: 180px;
}


.map-popup-lightindic {
    display:inline-block;
    width: 16px;
    height: 12px;
    background-color: #000;
    border-radius: 10px;
    vertical-align: middle;
    margin-right: 6px;
}

.map-popup-lightindic.light-red {background-color: #EB412D;}
.map-popup-lightindic.light-orange {background-color: #FFBE1E;}
.map-popup-lightindic.light-green {background-color: #00CC8A;}
.map-popup-lightindic.light-off {background-color: #3a3a3a;}
