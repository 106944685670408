.map-container {
    position: absolute;
    top: $topbar-height;
    left: $leftbar-width;
    right: 0px;
    bottom: 60px;
}

.enlarged .map-container {
    left: $leftbar-width-collapsed;
}


@include media-breakpoint-down(sm) {
    .map-container, .enlarged .map-container {
        left: 0px;
    }
}