@font-face {
    font-family: 'footable';
    src: url('../icons/footable.eot');
    src: url('../icons/footable.eot?#iefix') format('embedded-opentype'), url('../icons/footable.woff') format('woff'), url('../icons/footable.ttf') format('truetype'), url('../icons/footable.svg#footable') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
        font-family: 'footable';
        src: url('../icons/footable.svg#footable') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}